// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-email-sent-js": () => import("./../../../src/pages/email-sent.js" /* webpackChunkName: "component---src-pages-email-sent-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-offer-js": () => import("./../../../src/pages/offer.js" /* webpackChunkName: "component---src-pages-offer-js" */),
  "component---src-pages-tell-me-more-js": () => import("./../../../src/pages/tell-me-more.js" /* webpackChunkName: "component---src-pages-tell-me-more-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-video-launch-js": () => import("./../../../src/pages/video-launch.js" /* webpackChunkName: "component---src-pages-video-launch-js" */),
  "component---src-pages-video-offer-js": () => import("./../../../src/pages/video-offer.js" /* webpackChunkName: "component---src-pages-video-offer-js" */)
}

